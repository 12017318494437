import React from "react"

const Title = ({ title }) => {
  return (
    <div className="section-title">
      {/* もしなにもTitleに与えられていないときの処理を記述する。 */}
      <h2 className="page-title">{title || "default title"}</h2>
      {/* <div className="underline"></div> */}
    </div>
  )
}

export default Title
