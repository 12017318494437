import React, { useRef, useEffect } from "react"
import Title from "../components/Title"
import { GrMailOption } from "react-icons/Gr"
import SEO from "../components/Seo"
import styled from "styled-components"
import SketchHannyaComponent from "../components/SketchHannyaComponent"
const WaveSquare = () => {
  return (
    <>
      <SEO title="Kentaro Takahira" />
      <div style={{ backgroundColor: "#6d6c6c" }}>
        <div className="cakes-art art-background">
          <div className="cs-sankey">
            <div className="arts-center">
              <div className="art-block">
                <div className="sketchContainer" id="canvas-parent">
                  <SketchHannyaComponent></SketchHannyaComponent>
                  {/* <div id="ca nvasDiv"></div>    */}
                </div>
              </div>
              <div className="sketchDescription">
                <h2
                  className="sketchTitle railroadTitle"
                  style={{ textAlign: "center" }}
                >
                  Hannya Space
                </h2>
                <h3
                  className="sketchDesc railroadTitle"
                  style={{ textAlign: "center" }}
                >
                  Available in{" "}
                  <a
                    href={"https://openprocessing.org/sketch/1342629"}
                    className="observable_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Open Processing
                  </a>
                </h3>
                {/* <p className="sketchDetails">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Repudiandae suscipit unde, minima ipsa officia soluta dolor
                  illo nemo optio odio quis, voluptatem vel aliquid itaque?
                  Expedita quae quas, provident doloremque tenetur quidem beatae
                  voluptatum veritatis non dignissimos. Voluptatem ipsum,
                  eligendi possimus obcaecati, maxime eum minima mollitia
                  voluptatibus, doloribus consectetur repellendus. Quia
                  reiciendis impedit vitae numquam libero, placeat id temporibus
                  possimus in dolores, dolor, facere similique. Recusandae, illo
                  soluta! Mollitia maxime, at perspiciatis consectetur vero
                  possimus. Odio praesentium culpa pariatur laboriosam?
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WaveSquare
